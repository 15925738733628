@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  @apply bg-white shadow-xl p-8 w-full md:w-3/5;
  /* background-color: rgb(159, 144, 144); */
  /* width: 80%; */
}

.btn-primary{
  @apply bg-[#F95923] hover:bg-[#aa573c] transition-all duration-300 rounded-full text-white font-bold px-8 py-4;
}

.form-control{
  @apply p-2 md:py-2 lg:px-4 bg-[#e6e7e9] border-0 rounded-xl uppercase text-center text-sm md:text-3xl block w-full focus:outline-transparent;
}
